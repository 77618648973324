























import { Component, Vue } from "vue-property-decorator";

@Component
export default class DataTreatmentModal extends Vue {
  dataTreatment = false;
  text = `
    <h2>${this.$t("PersonalDataProcessing_Section1_Title")}</h2>
    <p>${this.$t("PersonalDataProcessing_Section1_Description1")}</p>
    <p>${this.$t("PersonalDataProcessing_Section1_Description2")}</p>
    <h2>${this.$t("PersonalDataProcessing_Section2_Title")}</h2>
    <p>${this.$t("PersonalDataProcessing_Section2_Description1")}</p>
    <p>${this.$t("PersonalDataProcessing_Section2_Description2")}</p>
    <p>${this.$t("PersonalDataProcessing_Section2_Description3")}</p>
    <h2>${this.$t("PersonalDataProcessing_Section3_Title")}</h2>
    <p>${this.$t("PersonalDataProcessing_Section3_Description1")}</p>
    <h2>${this.$t("PersonalDataProcessing_Section4_Title")}</h2>
    <p>${this.$t("PersonalDataProcessing_Section4_Description1")}</p>
    <h2>${this.$t("PersonalDataProcessing_Section5_Title")}</h2>
    <p>${this.$t("PersonalDataProcessing_Section5_Description1")}</p>
    `;

  setDTMVal(val: boolean): void {
    this.$store.dispatch("register/setDataCollection", !val);
  }
}
